import { Nullable }		from "ts-base/nullable";

import * as tracking	from "@geotoura/common/privacy/tracking";

export const testimonial = ():void => {
	const prev		= document.querySelector<HTMLElement>(".js-testimonials-prev");
	const next		= document.querySelector<HTMLElement>(".js-testimonials-next");
	const content	= document.querySelector<HTMLElement>(".js-testimonials-content");

	if (prev !== null) {
		prev.addEventListener("click", () => {
			const stats = getStats();
			if (stats === null)	return;

			const canGoLeft		= stats.currentItem > 0;

			// we're at the left edge already
			if (canGoLeft) {
				content?.scrollTo({
					left:		stats.currentScrollLeft - stats.offset,
					behavior:	"smooth",
				});
				prev.classList.toggle("testimonials-button-active", stats.currentItem > 1);
				next?.classList.toggle("testimonials-button-active", true);
			}

			tracking.gaSendEvent({
				action:		"testimonial-navigate",
				category:	"testimonial",
				label:		"testimonial-previousbutton",
				value:		1,
			});
		});
	}

	if (next !== null) {
		next.addEventListener("click", () => {
			const stats = getStats();
			if (stats === null)	return;

			const canGoRight	= stats.currentItem < stats.length - 1;

			// we're at the right edge already
			if (canGoRight) {
				content?.scrollTo({
					left:		stats.currentScrollLeft + stats.offset,
					behavior:	"smooth",
				});
				prev?.classList.toggle("testimonials-button-active", true);
				next.classList.toggle("testimonials-button-active", stats.currentItem < stats.length - 2);
			}

			tracking.gaSendEvent({
				action:		"testimonial-navigate",
				category:	"testimonial",
				label:		"testimonial-nextbutton",
				value:		1,
			});
		});
	}
};

type Stats = {
	length:				number,
	offset:				number,
	currentScrollLeft:	number,
	currentItem:		number,
};

const getStats = ():Stats|null => {
	const nodelist			= document.querySelectorAll<HTMLElement>(".js-testimonials-single-testimonial");
	const carouselPositions	= Array.from(nodelist).map((el) => [ el.offsetLeft, el.offsetLeft + el.offsetWidth ]);

	const length = carouselPositions.length;
	if (length < 2)	return null;

	const offset = carouselPositions[1][0] - carouselPositions[0][0];

	const container = Nullable.unsafeGet(document.querySelector<HTMLElement>(".js-testimonials-content"));
	const currentScrollLeft = container.scrollLeft;
	// const currentScrollRight = currentScrollLeft + container.offsetWidth;

	const currentItem = currentScrollLeft / offset;

	return {
		length,
		offset,
		currentScrollLeft,
		currentItem,
	};
};
