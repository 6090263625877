import { Nullable }	from "ts-base/nullable";
import { Dates }	from "ts-base/dates";

import { AsCookie }	from "@geotoura/shared/representation";

export const names	= ():ReadonlyArray<string>	=>
	all().map(it => it.name);

export const get = <T>(asCookie:AsCookie<T>):T|null =>
	Nullable.then(getRaw(asCookie.name))(asCookie.fromCookieValue);

export const set = <T>(asCookie:AsCookie<T>, value:T):void =>
	setRaw(asCookie.name, asCookie.toCookieValue(value), Dates.utc.future(asCookie.timeToLive));

export const remove = (name:string):void =>
	setRaw(name, "", Dates.epoch);

// NOTE resembles https://developer.mozilla.org/en-US/docs/Web/API/CookieStore

const all	= ():ReadonlyArray<{ name:string, value:string }>	=>
	document.cookie.split(";").map((part) => {
		const kv	= part.split("=");
		// TODO typesafe ugly cast
		const name	= (kv.shift() as string).trim();
		const value	= kv.join("=").trim();
		return { name, value };
	});

const getRaw = (name:string):string|null =>
	all().find(it => it.name === name)?.value ?? null;

const setRaw = (name:string, value:string, expiry:Date):void => {
	document.cookie = `${name}=${value}; path=/; expires=${expiry.toUTCString()};`;
};
